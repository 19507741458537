// My variables
$lighter-blue: #f3f3f4;
$light-blue: #b3b3b3;
$normal-blue: #b3b3b3;
$dark-blue: #424143;
$section-bg: $lighter-blue;

// Customized bootstrap variables
// $font-size-base: 0.71875rem; // 11.5/16
$font-size-base: 0.78125rem; // 12/16
$body-bg: #fff;
$primary: #ae2028;
$secondary: #404041;
$success: $normal-blue;
$tooltip-font-size: 12px;

$input-font-size: 14px;
$input-btn-font-size: 14px;

// Fonts
// @import url('https://fonts.googleapis.com/css?family=Lusitana&display=swap');

// Imports
@import '~bootstrap/scss/bootstrap.scss';
@import 'App.scss';
@import 'Services.scss';
@import 'Forms.scss';
@import 'Header.scss';
@import 'ContactInfo.scss';
@import 'MobileMenu.scss';
@import 'Modals.scss';
@import 'Languages.scss';
@import 'Testimonials.scss';
@import 'Footer.scss';
