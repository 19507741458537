h5 {
  font-size: $font-size-base;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.6;
}

.services {
  .item {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid $lighter-blue;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .tech-stack {
      margin-left: 10px;
    }
  }
}

@media (max-width: 767.98px) {
  .experience, .education {
    .item {
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
