/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed; // absolute
  width: 26px; // 36px;
  height: 25px; // 30px;
  right: 20px;
  top: 28px;
  border-radius: 1px;

  box-shadow: 0 0 0 6px rgba(64, 64, 65, 0.2);
  background-color: rgba(64, 64, 65, 0.2);
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
  height: 15% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  // background: darken($primary, 5%);
  background: #fff;
  opacity: 1 !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  right: 20px !important;
  top: 21px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
  height: 21px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $secondary;
  padding: 2.5em 1.5em 0 0.5em;
  font-size: 1.15em;
  position: relative;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 1.2em 0.8em 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Custom */
$menu-item-gap: 20px;

.menu-item {
  font-size: 0.875rem;
  font-weight: 500;
  margin: $menu-item-gap 0;
  color: #fff;
  text-decoration: none;

  &:hover, &:active {
    text-decoration: none !important;
    color: #fff;
  }

  .language-links {
    height: 34px;
    position: absolute;
    top: 5px;
    display: block;
    width: 264px;
    padding-bottom: 3px;
    border-bottom: 1px solid $dark-blue;

    .language-link {
      font-size: 12px;
    }
  }
  
  .separator-dl {
    display: inline-block;
    border-top: 1px solid $dark-blue;
    margin-top: 10px;
    padding-top: 15px;
    a {
      font-size: 15px;
    }
  }
}

