.testimonials {
  .item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .quote {
      //color: #666;
      font-size: $font-size-base;
      border-left: 5px solid $primary;
      padding: 0px 12px;
      margin-bottom: 12px;

      p {
        margin-bottom: 0;
      }

      .svg-inline--fa {
        color: $primary;
        margin-right: 8px;
      }
    }

    .source {
      font-size: $font-size-base;
      line-height: 1.4;
      font-weight: 500;

      img {
        border: 0 solid red;
        max-width: 80px;   
        margin: 0 20px;     
      }

      .name {
        color: $body-color;
        font-weight: 600;
      }

      .title {
        color: $body-color;
        a {
          color: $body-color;
        }
      }
    }
  }
}

@media(max-width: 767.98px) {
  .testimonials {
    .item {
      .quote {
        margin-bottom: 5px;
      }
    }
  }
}