.languages {
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .title {
      color: #778492;
    }

    .level {
      color: #999;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .svg-inline--fa {
      color: $light-blue;
    }
  }
}

@media (max-width: 767.98px) {
  .languages .item {
    display: block;
  }
}