.map-modal {
  width: 500px;
  max-width: 95%;
  .modal-content {
    background: white;
    border: 0 none transparent;
    max-width: 100%;

    .modal-body {
      padding: 0;

      .map-container {
        height: 450px;
        position: relative;
      }

      .spinner-border {
        z-index: 1;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 40%;
      }

      iframe {
        position: absolute;
        z-index: 2;
        max-width: 100%;
        border: 0!important;
      }
    }

    .modal-footer {
      border: 0;
      padding: 0;
    }
  }
}

.contact-modal {
  max-width: 350px;
  .modal-content {
    width: 350px;
  }
}

.portfolio-modal {
  max-width: 750px;
  .modal-content {
    width: 750px;
  }
}

@media(max-width: 767.98px) {
  .portfolio-modal {
    max-width: 95%;
    .modal-content {
      width: 100%;
    }
  } 
}

.modal-dialog {
  margin: 0 auto;

  .modal-header {
    border-bottom: 1px solid $light-blue;
    padding: 12px 0;
    width: 100%;
    text-align: center;
    background: $lighter-blue;

    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      position: relative;

      .close {
        position: absolute;
        top: 2px;
        right: 15px;
        padding-top: 1rem;
        color: $body-color;
        opacity: 1;
        font-size: 18px;
      }
    }
  }
}
