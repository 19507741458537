.info {

  .btn-contact {
    margin: 0 auto;
    margin-top: 25px;
    display: block;
  }

  ul {
    margin-bottom: 0;

    li {
      margin-bottom: 15px;
  
      &:last-child {
        margin-bottom: 0;
      }

      .svg-inline--fa {
        margin-right: 15px;
        color: $dark-blue;
      }
      .svg-inline--fa.fa-envelope-o {
        //font-size: 14px;
      }
    }
  } 
}