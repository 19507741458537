form {

  label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10.5px;
    margin-bottom: 5px;
  }

  .error {
    font-size: 11px;
    color: $danger;
  }

  .form-control {
    &:focus {
      background-color: inherit;
    }
    &.input-error {
      border-color: $danger;
      background-color: lighten($danger, 44%);
    }
  }
}