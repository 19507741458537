.header {
  padding: 8px 0 30px;
  background: $secondary;

  .btn {
    margin-top: 24px;
    font-weight: bold;

    &.btn-contact {
      margin-right: 5px;
    }
    &.btn-download {
      padding: 8px 6px 8px 10px;
    }
  }
  .profile-image {
    margin-top: 22px;
    margin-right: 22px;
    max-width: 80px;
  }

  .profile-content {
    max-width: 450px;
    .name {
      color: #fff;
      margin-bottom: 5px;
      margin-top: 31px;
      font-weight: 600;
      font-size: 1.8rem;
    }
    .desc {
      font-size: 0.95rem;
      color: #fff;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  .button-container {
    text-align: right;
    float: right;
  }

  .icons {
    border: 0px solid red;
    position: relative;

    .dropdown {
      position: absolute;
      top: 2px;
      right: 35px;
      display: inline-block;
      width: 40px;
      margin-right: 4px;
  
      button.dropdown-toggle {
        margin-top: 0;
        padding: 4px 4px 4px;
        background-color: $gray-200;
        border: 0;
      }
  
      .dropdown-menu {
        min-width: auto;
        padding: 2px 0;
      }
      
      .dropdown-item {
        text-align: center;
        padding: 2px;
        width: 38px;
      }
    }

    a.icon {
      background: $gray-200;
      width: 30px;
      height: 30px;
      display: inline-block;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      -moz-background-clip: padding;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      color: $body-color;
      text-align: center;
  
      &:hover {
        background: #778492;
      }
  
      .svg-inline--fa {
        font-size: 15px;
        margin-top: 8px;
      }
    }
  }
}

@media (min-width: 767.99px) and (max-width: 991.98px) {
  .header .button-container .btn-contact {
    display: none;
  }
  .bm-burger-button {
    top: 50px !important;
  }
  
}

@media (max-width: 767.98px) {
  .header {
    text-align: center;
    padding-bottom: 15px;

    .profile-image {
      float: none !important;
      margin: 20px auto 0;
    }
  
    .profile-content {
      max-width: unset;
      float: none !important;
      text-align: center;
      padding: 0 36px;

      .name {
        margin-top: 18px;
        // font-size: 34px;
      }
      .desc {
        // font-size: 20px;
      }
      margin-bottom: 0px;
    }

    .button-container {

      float: none;
      display: none;
      text-align: center;

      .icons {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        text-align: right;
        // display: inline;

        .dropdown {
          position: relative;
          right: auto;
          top: -2px;
          margin-right: 6px;
        }
        .icon {
          margin-top: 2px;
          margin-right: 0;
        }
      }

      .btn {
        margin-top: 0px;
        float: none !important;
      }
    }
  }
}